/** @format */

import { ExternalLink, InternalLink } from '@/types/page';
import { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document, INLINES, MARKS } from '@contentful/rich-text-types';
import { Entry } from 'contentful';
import { FunctionComponent as FC, ReactNode, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { breakpointFrom, breakpointUpTo } from '../../theme/mixins';
import { FindColor } from '../../utils/findThemeColor';
import { Image } from '../Image';
import { StyledButton } from './Button/StyledButton';
import { Container, ContainerWrapper } from './Layout';
import { RichText } from './RichText';
import { H1, H2, H3, H4, P, Subtitle } from './Typography';

export const CenterBigTitle: FC<Props> = ({
  id,
  label,
  backgroundColor,
  textColor,
  textSecondColor,
  type,
  mobileAlign = 'center',
}) => {
  const theme = useContext(ThemeContext);
  const banner = label.content.some(el => el.nodeType === 'hr');
  const pageManifest = type === 'manifest';

  return (
    <Wrapper
      as="section"
      $id={id}
      $color={FindColor(backgroundColor.replace(' ', '.').toLowerCase(), theme!.color)}
      $textColor={FindColor(textColor.replace(' ', '.').toLowerCase(), theme!.color)}
      $textSecondColor={textSecondColor ? FindColor(textSecondColor, theme!.color) : ''}
      $banner={banner}
      $pageManifest={pageManifest}
      $mobileAlign={mobileAlign}
    >
      <StyledContainer
        $backgroundColor={backgroundColor.toLocaleLowerCase()}
        $banner={banner}
        $pageManifest={pageManifest}
        $textSecondColor={textSecondColor}
      >
        <RichText text={label} customOptions={options} />
      </StyledContainer>
    </Wrapper>
  );
};

const options: Options = {
  renderMark: {
    [MARKS.ITALIC]: (text: ReactNode) => <em>{text}</em>,
  },
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => <H1>{children}</H1>,
    [BLOCKS.HEADING_2]: (node, children) => <H2>{children}</H2>,
    [BLOCKS.HEADING_3]: (node, children) => <H3>{children}</H3>,
    [BLOCKS.HEADING_4]: (node, children) => <H4>{children}</H4>,
    [BLOCKS.HEADING_5]: (node, children) => <Subtitle>{children}</Subtitle>,
    [BLOCKS.HEADING_6]: (node, children) => <Subtitle>{children}</Subtitle>,
    [BLOCKS.PARAGRAPH]: (node, children) => {
      // to avoid rendering empty paragraphs
      if (Array.isArray(children) && children.length === 1 && children[0] === '') return;
      return <P>{children}</P>;
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
      if (
        node.data.target.sys.contentType.sys.id === 'internalLink' ||
        node.data.target.sys.contentType.sys.id === 'externalLink'
      ) {
        const item: Entry<InternalLink['fields'] | ExternalLink['fields']> = node.data.target;
        return (
          <StyledButton
            item={item}
            position={item.fields.position ?? 'right'}
            variant={item.fields.variant ?? 'bgTeal'}
            elementType="magic"
          >
            {item.fields.label}
          </StyledButton>
        );
      }
    },
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      if (
        node.data.target.sys.contentType.sys.id === 'internalLink' ||
        node.data.target.sys.contentType.sys.id === 'externalLink'
      ) {
        const item: Entry<InternalLink['fields'] | ExternalLink['fields']> = node.data.target;
        return (
          <StyledButton
            item={item}
            position={item.fields.position ?? 'right'}
            variant={item.fields.variant ?? 'bgTeal'}
            elementType="magic"
          >
            {item.fields.label}
          </StyledButton>
        );
      }
    },
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      const isSVG = node.data.target.fields.file.contentType === 'image/svg+xml';
      if (node.data.target.fields.file.contentType.includes('image')) {
        return <Image image={node.data.target} placeholder={!isSVG} />;
      }
    },
  },
};

type Props = {
  id: string;
  label: Document;
  backgroundColor: string;
  textColor: string;
  textSecondColor: string;
  type?: string;
  mobileAlign?: string;
};

const StyledContainer = styled(Container)<{
  $backgroundColor: string;
  $banner: boolean;
  $pageManifest: boolean;
  $textSecondColor: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: ${({ $pageManifest }) => ($pageManifest ? 'start' : 'center')};
  text-align: ${({ $pageManifest }) => $pageManifest && 'start'};
  --padding: ${({ $pageManifest }) => $pageManifest && '20rem'};
  margin-inline: ${({ $pageManifest }) => $pageManifest && 0};
  gap: ${({ $pageManifest, $banner }) => ($pageManifest ? '1rem' : $banner && '2rem')};

  ${breakpointUpTo.lg`
     --padding: ${({ $pageManifest }: { $pageManifest: boolean }) => $pageManifest && '0 !important'};

     h2{
      margin-top: ${({ $pageManifest }: { $pageManifest: boolean }) => $pageManifest && '2rem'};;
     }
    `}

  h1 {
    font-size: ${({ $pageManifest }) => $pageManifest && 'clamp(2.5rem, 1.0035rem + 6.3850vw, 6.75rem);'};
    display: ${({ $pageManifest }) => $pageManifest && 'flex'};
    gap: ${({ $pageManifest }) => $pageManifest && '2rem'};

    em {
      font-size: ${({ $pageManifest }) => $pageManifest && 'clamp(2.5rem, 1.0035rem + 6.3850vw, 6.75rem);'};
      color: ${({ theme, $textSecondColor, $pageManifest }) =>
        $pageManifest
          ? theme.color.teal.light
          : $textSecondColor
          ? FindColor($textSecondColor ?? 'white', theme?.color)
          : theme.color.teal.dark};
    }

    ${breakpointUpTo.xxl`
    flex-direction: ${({ $pageManifest }: { $pageManifest: boolean }) => $pageManifest && 'column'};
    `}
    ${breakpointUpTo.lg`
    gap: ${({ $pageManifest }: { $pageManifest: boolean }) => $pageManifest && '0 !important'};
    `}
    ${breakpointUpTo.md`
    padding-bottom: ${({ $pageManifest }: { $pageManifest: boolean }) => $pageManifest && '0 !important'};
    `}
  }

  div + hr {
    width: 2.5rem;
    border: 0.1rem solid ${({ theme }) => theme.color.yellow};
  }

  img {
    position: ${({ $pageManifest }) => $pageManifest && 'absolute'};
    top: ${({ $pageManifest }) => $pageManifest && 'clamp(-15rem, -1.4789rem + -15.0235vw, -7rem)'};
    right: ${({ $pageManifest }) => $pageManifest && 'clamp(-18rem, -0.4225rem + -19.5305vw, -5rem)'};
    width: ${({ $pageManifest }) => ($pageManifest ? 'clamp(20rem, -3.2817rem + 75.8685vw, 56rem)' : 'auto')};
    max-height: ${({ $pageManifest }) => ($pageManifest ? 'clamp(20rem, -3.2817rem + 75.8685vw, 56rem)' : '7rem')};
  }

  h3 + div > img {
    align-self: center;
    width: 2.725rem;
    height: 2.725rem;
  }

  ${breakpointUpTo.md`
     align-items: ${({ $banner }: { $banner: boolean }) => $banner && 'start'} ;
     text-align: ${({ $banner }: { $banner: boolean }) => $banner && 'start'} ;
     img {
     width:${({ $pageManifest }: { $pageManifest: boolean }) => !$pageManifest && '14.61106rem'};
     height:${({ $pageManifest }: { $pageManifest: boolean }) => !$pageManifest && '4.349rem'} ;
  }
    `}

  p {
    ${({ $backgroundColor, theme }) =>
      $backgroundColor === 'yellow' &&
      `
    font-size: clamp(${theme.font.size[1]}, 0.9583rem + 0.1852vw, ${theme.font.size[1.125]})!important;`}
  }
`;

const Wrapper = styled(ContainerWrapper)<{
  $id: string;
  $textSecondColor: string;
  $banner: boolean;
  $pageManifest: boolean;
  $mobileAlign: string;
}>`
  overflow: ${({ $pageManifest }: { $pageManifest: boolean }) => $pageManifest && 'visible'};
  padding-inline: 5rem;
  padding-block: ${({ $id }: { $id: string }) =>
    $id === 'Parla con il nostro team'
      ? 'clamp(3.75rem, 2.3333rem + 6.2963vw, 8rem) clamp(3.75rem, 2.3333rem + 6.2963vw, 4rem)'
      : 'clamp(3.75rem, 2.3333rem + 6.2963vw, 8rem);'};

  text-align: center;

  ${breakpointUpTo.md`
   padding-inline: 1rem;
   padding-block: ${({ $banner }: { $banner: boolean }) => $banner && '1rem 5.75rem !important'};
   text-align: ${({ $mobileAlign }: { $mobileAlign: string }) => $mobileAlign ?? 'center'};
  `}
  ${breakpointFrom.lg`
   padding-inline:${({ $banner, $pageManifest }: { $banner: boolean; $pageManifest: boolean }) =>
     $banner ? 'clamp(10rem, 26.3636rem + -18.1818vw, 15rem) !important' : $pageManifest ? '3rem' : '13rem !important'};
  `}
  ${breakpointUpTo.xl`
   padding-inline:${({ $pageManifest }: { $pageManifest: boolean }) =>
     $pageManifest && 'clamp(1rem, 0.3333rem + 2.9630vw, 3rem) !important'}
  `}

  a {
    margin-top: clamp(2rem, 1.8333rem + 0.7407vw, 2.5rem);
  }

  h2,
  h3 {
    font-family: ${({ theme }) => theme.font.title};
    color: ${({ $textColor }) => $textColor};
    line-height: clamp(2rem, 1.4167rem + 2.5926vw, 3.75rem);
    b {
      font-weight: 700 !important;
    }
    em {
      font-family: ${({ theme }) => theme.font.title} !important;
      color: ${({ theme, $textSecondColor, $pageManifest }) =>
        $pageManifest ? theme.color.yellow : $textSecondColor ? $textSecondColor : theme.color.teal.dark};
      font-weight: 400 !important;
    }
  }
  h4 {
    font-family: ${({ theme }) => theme.font.accent};
    color: ${({ $textColor }) => $textColor};
    font-weight: 600 !important;
  }

  h2 + h3 {
    margin-top: clamp(1.62rem, 1.9667rem + -0.3852vw, 1.88rem);
    color: ${({ theme }) => theme.color.teal.light};
  }

  h2 + p {
    // same as PBig
    font-family: ${({ theme }) => theme.font.title};
    font-size: ${({ theme }) => `clamp(${theme.font.size[1.4375]}, 1.3333rem + 0.4630vw, ${theme.font.size[1.75]});`};
    font-style: normal;
    font-weight: 300;
    line-height: clamp(2.3125rem, 2.0896rem + 0.9907vw, 2.98125rem);
    letter-spacing: clamp(0.02875rem, 0.0267rem + 0.0093vw, 0.035rem);
    color: ${({ $textColor }) => $textColor};
    margin: 1rem 0;
  }

  hr + p {
    // same as subtitle
    font-family: ${({ theme }) => theme.font.title};
    font-size: ${({ theme }) => `clamp(${theme.font.size[1]}, 0.9167rem + 0.3704vw,${theme.font.size[1.25]});`};
    font-style: normal;
    font-weight: 400;
    line-height: clamp(1.25rem, 1.0833rem + 0.7407vw, 1.6875rem);
    letter-spacing: clamp(0.03375rem, 0.0313rem + 0.0111vw, 0.0375rem);
    color: ${({ $textColor }) => $textColor};
  }

  p + h2 {
    margin-top: clamp(4rem, 3.5rem + 2.2222vw, 5.5rem);
  }

  img {
    margin-top: clamp(1.75rem, 1.9233rem + -0.1926vw, 1.88rem);
  }

  h3 + h2 {
    @media screen and (min-width: 995px) and (max-width: 1950px) {
      width: 140% !important;
    }

    margin: 2rem 0;
    line-height: clamp(3rem, 1.6667rem + 5.9259vw, 7rem);
    em {
      font-size: clamp(3rem, 1.75rem + 5.5556vw, 6.75rem);
    }
  }
`;
